import React from 'react';
import { FaPhoneAlt, FaClock } from 'react-icons/fa';

const HeaderTop = () => {
    return (
        <div className="header-top pt-15 pb-15 bg-1">
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                        <div className="header-info">
                            <ul>
                                <li>
                                    <FaPhoneAlt /> Appelez au :
                                    <a href="tel:0687847244"> 06 87 84 72 44</a>
                                </li>
                                <li>
                                    <FaClock /> Horaires : Lun-Ven 08:00 - 18:00
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTop;