import React from "react";
import HeaderTop from "../common/header/HeaderTop";
import HeaderBottomOne from "../common/header/HeaderBottomOne";
import Hero from "../components/hero/Hero";
import Feature from "../components/features/Features";
import WhyChoseUs from "../components/why-chose-us/WhyChoseUs";
import Service from "../components/sevices/Service";
import HowItWork from "../components/how-it-work/HowItWork";
import Project from "../components/home-project/Project";
import Appointment from "../components/home-appointment/Appointment";
import Footer from "../common/footer/Footer";


const Home = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomOne />
            <Hero />
            <Feature />
            <WhyChoseUs />
            <Service />
            <Project />
            <HowItWork />
            <Appointment />
            <Footer />
        </>
    )
}

export default Home;