import React from 'react';
import {Link} from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import arbreavap from '../../assets/images/project/arbreavap.gif';
import penteavap from '../../assets/images/project/penteavap.gif';
import doubleavap from '../../assets/images/project/doubleavap.gif';
import jardin from '../../assets/images/project/jardin.gif';
import passage from '../../assets/images/project/passage.gif';

const ProjectData = [
    
    {
        id: uuidv4(),
        img: arbreavap,
        heading: 'Rafraichissement',
        description: 'Nettoyage, pose de cailloux et de copeaux',
    },
    
    {
        id: uuidv4(),
        img: penteavap,
        heading: 'Refonte totale',
        description: 'Démarquations, délimitations, pose de plants, pose de cailloux',
    },
    
    {
        id: uuidv4(),
        img: doubleavap,
        heading: 'Réhabilitation place voiture',
        description: 'Désherbage, applanissement du terrain, délimitations et pose de gravier',
    },
    {
        id: uuidv4(),
        img: jardin,
        heading: 'Refonte jardin',
        description: "Désherbage, pose de pelouse, pose d'un système d'arrosage automatique",
    },
    {
        id: uuidv4(),
        img: passage,
        heading: 'Passage',
        description: "Désherbage, pose de pelouse, pose de cailloux et de dalles, plants délimités",
    },

];

const ProjectItem = ({ img, heading, description }) => {
    return (
        <>
            <div className="col-xl-4 col-lg-4 col-md-4">
                <div className="single-project">
                    <div className="single-project-img bg-property" style={{ backgroundImage: `url(${img})` }} />
                    <div className="single-project-content">
                        <h3>{heading}</h3>
                        <p>{description}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

const Project = () => {
    return (
        <>
            <div className="project-area pt-110 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title text-center mb-70">
                                <span>Nos projets</span>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-60">
                        {ProjectData.map((data) => (
                            <ProjectItem
                                key={data.id}
                                img={data.img}
                                heading={data.heading}
                                description={data.description}
                                btnLink={data.btnLink}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Project;