import React from 'react';
import {Link} from 'react-router-dom';
import FooterBG from '../../assets/images/footer.png';
import { FaPhoneAlt } from 'react-icons/fa';

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer-top bg-property theme-bg-2 pt-110 pb-80" style={{ backgroundImage: `url(${FooterBG})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <div className="footer-widget-1">
                                    <div className="footer-title">
                                        <h4>Contactez-nous</h4>
                                    </div>
                                    <div className="footer-text">
                                        <p>N'hésitez pas à nous contacter pour la moindre question.</p>
                                    </div>
                                    <div className="footer-info">
                                    <a href="tel:0687847244"><FaPhoneAlt /> 06 87 84 72 44</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                                <div className="footer-widget-2">
                                    <div className="footer-title">
                                        <h4>Liens rapides</h4>
                                    </div>
                                    <div className="footer-menu">
                                        <ul>
                                            <li><Link to="/services">Nos services</Link></li>
                                            <li><Link to="/contact">Contact</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                                <div className="footer-widget-3">
                                    <div className="footer-title">
                                        <h4>Nos Services</h4>
                                    </div>
                                    <div className="footer-menu">
                                        <ul>
                                            <li><Link to="/">Paysagisme</Link></li>
                                            <li><Link to="/">Cultures</Link></li>
                                            <li><Link to="/">Elagage</Link></li>
                                            <li><Link to="/">Entretien</Link></li>
                                            <li><Link to="/">Plantation d'arbres</Link></li>
                                            <li><Link to="/">Nettoyage</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <div className="footer-widget-4">
                                    <div className="footer-text">
                                        <p>Depuis 2015, basé dans le Val d'Oise et l'Oise</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom bg-1 pt-40 pb-30">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div className="copyright">
                                    <p>© 2023 Ab Paysages Tout droits réservés.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;