import React from 'react';
import Feature1 from '../../assets/images/icon/satisfaction-guarantee.png';
import Feature2 from '../../assets/images/icon/professional-team.png';
import Feature3 from '../../assets/images/icon/material-instrument.png';
import Feature4 from '../../assets/images/icon/experience.png';

const Feature = () => {
    return (
        <>
            <div className="features-area bg-white pt-110 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="single-feature text-center box-shadow-3">
                                <div className="single-feature-icon">
                                    <img src={Feature1} alt="" />
                                </div>
                                <h5>Satisfaction Guarantie</h5>
                                <p>Nous voulons mettre votre satisfaction au coeur de notre métier</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="single-feature text-center box-shadow-3">
                                <div className="single-feature-icon">
                                    <img src={Feature2} alt="" />
                                </div>
                                <h5>Une équipe de professionels</h5>
                                <p>L'équipe est constitué de professionels reconnus par l'état</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="single-feature text-center box-shadow-3">
                                <div className="single-feature-icon">
                                    <img src={Feature3} alt="" />
                                </div>
                                <h5>Bien équipés</h5>
                                <p>Un équipement dernier cri pour s'occuper de votre jardin</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="single-feature text-center box-shadow-3">
                                <div className="single-feature-icon">
                                    <img src={Feature4} alt="" />
                                </div>
                                <h5>10 Années d'expérience</h5>
                                <p>Nous savons nous occuper de votre jardin, et ce depuis 10 ans maintenant</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Feature;