import React, { useState } from 'react';
import { FaArrowRight } from "react-icons/fa";
import HomeImg1 from '../../assets/images/appointment.png';
import emailjs from '@emailjs/browser';

const Appointment = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [text, setText] = useState("");

    const submitMail = async (e) => {
        
        e.preventDefault();
        emailjs.send(process.env.REACT_APP_SERVICE_ID,process.env.REACT_APP_TEMPLATE_ID,{
            name,
            email,
            subject,
            text,
        }, process.env.REACT_APP_PUBLIC_KEY);
        alert("votre message a bien été envoyé nous vous recontacterons sous peu!")
        console.log("done")
    }

    const changeName = (e) => {
        setName(e.target.value);
    }
    
    const changeEmail = (e) => {
        setEmail(e.target.value);
    }
    
    const changeSubject = (e) => {
        setSubject(e.target.value);
    }

    const changeText = (e) => {
        setText(e.target.value);
    }

    return (
        <>
            <div className="book-appointment">
                <div className="book-appointment-img bg-property" style={{ backgroundImage: `url(${HomeImg1})` }}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 offset-xl-6 col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-sm-12">
                            <div className="book-quote-form ml-65 pt-70 pb-70">
                                <div className="section-title mb-70">
                                    <span>Demandez un rendez-vous</span>
                                    <h2>Un devis gratuit fait<br /> par nos experts</h2>
                                </div>
                                <form onSubmit={submitMail}>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <input onChange={changeName} required value={name} type="text" placeholder="Nom" />
                                        </div>
                                        <div className="col-xl-6">
                                            <input onChange={changeEmail} required value={email} type="email" placeholder="Email" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <input onChange={changeSubject} required value={subject} type="text" placeholder="Sujet" />
                                            <textarea onChange={changeText} required value={text} placeholder="Votre message"></textarea>
                                        </div>
                                    </div>
                                    <button className="l-btn quote-btn">Envoyer un message <FaArrowRight /></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Appointment;