import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import { FaArrowRight, FaHeadphones, FaEnvelopeOpen, FaClock } from "react-icons/fa";
import emailjs from '@emailjs/browser';

const ContactForm = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [text, setText] = useState("");
    
    const submitMail = async (e) => {
        
        e.preventDefault();
        emailjs.send(process.env.REACT_APP_SERVICE_ID,process.env.REACT_APP_TEMPLATE_ID,{
            name,
            email,
            subject,
            text,
        }, process.env.REACT_APP_PUBLIC_KEY);
        alert("votre message a bien été envoyé nous vous recontacterons sous peu!")
        console.log("done")
    }

    const changeName = (e) => {
        setName(e.target.value);
    }
    
    const changeEmail = (e) => {
        setEmail(e.target.value);
    }
    
    const changeSubject = (e) => {
        setSubject(e.target.value);
    }

    const changeText = (e) => {
        setText(e.target.value);
    }

    return (
        <>
            <div className="contact-area white-bg pt-110 pb-110">
                <div className="container">
                    <div className="box-shadow-3 pt-60 pb-60 pl-80 pr-80">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6">
                                <div className="contact-form pr-70">
                                    <div className="section-title mb-40">
                                        <span>Besoin de nos services ou vous avez une question ?</span>
                                        <h2>Envoyez-nous un message.</h2>
                                    </div>
                                    <form  onSubmit={submitMail}>
                                        <input onChange={changeName} required value={name} type="text" placeholder="Nom" />
                                        <input onChange={changeEmail} required value={email} type="email" placeholder="Email" />
                                        <input onChange={changeSubject} required value={subject} type="text" placeholder="Sujet" />
                                        <textarea onChange={changeText} required value={text} placeholder="Votre message..."></textarea>
                                        <button className="l-btn">Envoyer un message <FaArrowRight /></button>
                                    </form>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6">
                                <div className="contact-box bg-1 pt-60 pb-35 pl-35 pr-35 mr-40">
                                    <h3>Nos coordonnées de contacts : </h3>
                                    <ul className="mt-40">
                                        <li><FaHeadphones /><span>Numéro de téléphone: <Link to="/">06 87 84 72 44</Link></span></li>
                                        <li><FaEnvelopeOpen /><span>Email: <Link to="/">ab-paysages@gmail.com</Link></span></li>
                                        <li><FaClock /><span>Lun - Ven: 8:00 - 18:00</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactForm;