import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Icon_landscape from '../../assets/images/service/landscape.png';
import Icon_growing from '../../assets/images/service/growing.png';
import Icon_harvest from '../../assets/images/service/harvest.png';
import Icon_flower from '../../assets/images/service/flower.png';
import Icon_tree from '../../assets/images/service/tree.png';
import Icon_rubbish from '../../assets/images/service/rubbish.png';

const ServiceData = [
    {
        id: uuidv4(),
        heading: 'Paysagisme',
        img: Icon_landscape,
    },
    {
        id: uuidv4(),
        heading: 'Entretiens des plants',
        img: Icon_growing,
    },
    {
        id: uuidv4(),
        heading: 'Elaguage',
        img: Icon_harvest,
    },
    {
        id: uuidv4(),
        heading: 'Entretien des fleurs',
        img: Icon_flower,
    },
    {
        id: uuidv4(),
        heading: 'Plantation d\'arbres',
        img: Icon_tree,
    },
    {
        id: uuidv4(),
        heading: 'Rafraichissement',
        img: Icon_rubbish,
    },

];

const ServiceItem = ({ img, heading, description }) => {
    return (
        <>
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="single-service">
                    <div className="service-icon">
                        <img src={img} alt="Service Icon" />
                    </div>
                    <h3>{heading}</h3>
                    <p>{description}</p>
                </div>
            </div>
        </>
    )
}

const Service = () => {
    return (
        <>
            <div className="service-area bg-4 pt-110 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title text-center mb-70">
                                <span>Nos services</span>
                                <h2>Le plus demandé</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {ServiceData.map((data) => (
                            <ServiceItem
                                key={data.id}
                                img={data.img}
                                heading={data.heading}
                                description={data.description}
                            />
                        ))}
                        <div className="col-xl-6 col-lg-12 col-md-12">
                            <div className="single-service-large">
                                <h3>Nous sommes des professionnels <span>Service de paysagisme et jardinerie</span></h3>
                                <p>Nous savons comment entretenir votre jardin en fonction de vos envies</p>
                                <a href="/contact" className="l-btn">Prendre rendez-vous</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Service;